<template>
<div class="mainform">
  <div class="mainHeader">供应商生命周期调整审批<span @click="back" class="back">返回></span></div>
  <div class="form">
    <el-form ref="form" :model="form" class="content bascform"  label-width="130px">
      <div  class="col col4">
        <el-form-item label="供应商名称" prop="providerName">
          <span class="onlyText">
            {{form.providerName}}
          </span>
        </el-form-item>
      </div>
      <div  class="col col4">
        <el-form-item label="供应商编码" prop="providerCode">
          <span class="onlyText">
            {{form.providerCode}}
          </span>
        </el-form-item>
      </div>
      <div  class="col col4">
        <el-form-item label="考核周期" prop="assessCycle">
          <span class="onlyText">
            {{form.assessCycle}}
          </span>
        </el-form-item>
      </div>
      <div  class="col col4">
        <el-form-item label="供应商当前级别" prop="adjustBeforeLevel">
          <span class="onlyText">
            {{form.adjustBeforeLevel=='1'?'一次':form.adjustBeforeLevel=='2'?'一般':form.adjustBeforeLevel=='3'?'合格':'淘汰'}}
          </span>
        </el-form-item>
      </div>
      <div class="col col4">
        <el-form-item label="调整建议" prop="adjustAdvice">
          <span class="onlyText">
            {{form.adjustAdvice=='1'?'一次升级到一般':form.adjustAdvice=='2'?'一次升级到合格':form.adjustAdvice=='3'?'一般升级到合格'
            :form.adjustAdvice=='4'?'一般降级到一次':form.adjustAdvice=='5'?'合格降级到一般':form.adjustAdvice=='6'?'合格降级到淘汰'
            :form.adjustAdvice=='7'?'一次降级到淘汰':'一般降级到淘汰'}}
          </span>
        </el-form-item>
      </div>
      <div  class="col col4">
        <el-form-item label="供应物料" prop="materialType">
          <span class="onlyText">
            {{form.materialType}}
          </span>
        </el-form-item>
      </div>
      <div  class="col col4">
        <el-form-item label="申请人" prop="applyUserName">
          <span class="onlyText">
            {{form.applyUserName}}
          </span>
        </el-form-item>
      </div>
      <div  class="col col4">
        <el-form-item label="经营范围" prop="businessScope">
          <span class="onlyText">
            {{form.businessScope}}
          </span>
        </el-form-item>
      </div>
      <div  class="col col4" style="vertical-align: top;">
        <el-form-item label="资质文件" prop="fileList">
          <span style="line-height: 0px;">
            <el-upload
            :action="actionURL"
            :on-preview="handlePreview"
            :headers="tokenHeader"
            :on-success="handleSuccess"
            :file-list="form.fileList"></el-upload>
          </span>
        </el-form-item>
      </div>
      <div  class="col col4" style="vertical-align: top;">
        <el-form-item label="调整依据" prop="">
          <span class="onlyText">
            <el-button size="mini" type="text" @click="handleShow()">
              <div style="text-align:left;">交易次数：{{form.dealNumber}}次</div>
              <div style="margin-top: 5px;">累计金额（万元）：{{form.dealAmount}}W</div>
            </el-button>
          </span>
        </el-form-item>
      </div>
    </el-form>
    <approval-mind></approval-mind>
    <div class="action" v-if="form.approvalStatus==1&& canAction ||form.approvalStatus==2&& canAction">
      <el-button @click="reject" class="save" type="primary" size="medium">驳回</el-button>
      <el-button  @click="pass" class="submit" type="primary" size="medium">通过</el-button>
    </div>
  </div>
  <dialog-box title="驳回" dialogWidth="50%" componentName="RejectBtnDialog" :adjustmentData ="form" ref="refusereceipt"  :dialogShow="dialogShow" @handleClose='handleClose'
  @confirmRefused="confirmRefused" :buttonData="[{ label: '取消', size: 'mini', action: 'handleClose' },{ label: '确认', size: 'mini', action: 'confirmRefused', type: 'primary' }]" ></dialog-box>

  <dialog-box title="通过" :adjustmentData ="form" componentName="passDialog" ref="conform" :dialogShow="confirmShow" @handleClose='handleClose'   @affirmapply="affirmapply"
  :buttonData="[{ label: '取消', action: 'handleClose', size: 'mini' },{ label: '确认', size: 'mini', action: 'affirmapply', type: 'primary' }]" ></dialog-box>

  <!-- 调整依据弹框 -->
  <dialog-box  dialogWidth="50%" componentName="adjustmentBasesDialog" :adjustmentData ="form"   :dialogShow="adjustmentdialogShow" @handleClose='handleClose'  title="调整依据"
  :buttonData="[{ label: '取消', action: 'handleClose', size: 'mini' },{ label: '确认', size: 'mini', action: 'handleClose', type: 'primary' }]" ></dialog-box>
</div>
</template>

<script>
import Component from '@/assets/js/components.js'
import { request } from '@/assets/js/http.js'
import { baseHost } from '@/assets/js/PublicData.js'
import { fileHandle } from '@/assets/js/FileAction.js'
import { FlowCtlData } from '@/assets/js/FlowManage.js'
export default {
  name: 'SupLifecycleApproval',
  components: Component.components,
  data () {
    return {
      actionURL: baseHost.SupplierRegHost + '/api/file/m/file/uploadToServer',
      dialogShow: false,
      confirmShow: false,
      canAction: false,
      adjustmentdialogShow: false,
      tokenHeader: {
        token: localStorage.getItem('token')
      },
      adjustmentData: {},
      form: {
        fileList: []
      }
    }
  },
  created () {
    const id = this.$route.query.id
    // const id = '577379978363744256'
    request('/api/supplier/supplierLevel/getDataById?id=' + id, 'get').then((res) => {
      if (res.code === '200') {
        this.form = res.data
        this.form.fileList = res.data.fileList
      }
      this.form.cpyzlbhq = ''
      this.form.cgbjlsp = ''
      FlowCtlData.getApprPsn('554180635259949056').then((val) => { this.form.cpyzlbhq = this.form.cpyzlbhq + val })
      FlowCtlData.getApprPsn('542244799739269120').then((val) => { this.form.cpyzlbhq = this.form.cpyzlbhq + val })
      FlowCtlData.getApprPsn('551625315191681024').then((val) => { this.form.cgbjlsp = val })
    })
    FlowCtlData.flowid = 'Lifecycle'
    FlowCtlData.docid = id
    FlowCtlData.initialize().then(() => {
      if (FlowCtlData.nodeThisData[0].APPROVER.split(';').includes(FlowCtlData.loginID)) {
        this.canAction = true
      } else {
        this.canAction = false
      }
    })
  },
  methods: {
    handleClose () {
      this.dialogShow = false
      this.confirmShow = false
      this.adjustmentdialogShow = false
    },
    confirmRefused () { // 驳回确认
      var obj = {
        id: this.$route.query.id,
        // id: '577379978363744256',
        approvalStatus: '1',
        approvalComplete: '',
        approval: ''
      }
      request('/api/supplier/supplierLevel/updateApprovalStatus', 'get', obj).then((res) => {
        if (res.code === '200') {
          this.$message({
            type: 'success',
            message: '驳回成功'
          })
          this.$router.push('/Approve/ApprovalList?ViewId=SupplierLifecycle')
        }
      })
      this.dialogShow = false
    },
    affirmapply () { // 通过确认
      var obj = {
        id: this.$route.query.id,
        approvalStatus: '3',
        approvalComplete: '',
        approval: ''
      }
      request('/api/supplier/supplierLevel/updateApprovalStatus', 'get', obj).then((res) => {
        if (res.code === '200') {
          this.$message({
            type: 'success',
            message: '确认成功'
          })
          this.$router.push('/Approve/ApprovalList?ViewId=SupplierLifecycle')
        }
      })
      this.confirmShow = false
    },
    reject () { // 驳回
      FlowCtlData.getNodeAction('BH', this.form).then((val) => {
        if (val) {
          FlowCtlData.alertBHDialog(this).then(({ value }) => {
            FlowCtlData.setFormData(FlowCtlData, this.form)
            request('/api/supplier/supplierLevel/updateApprovalStatus', 'get', this.form).then((Response) => {
              if (Response.code === '200') {
                FlowCtlData.setNodeAction(value).then((val) => {
                  FlowCtlData.formAlert(this, 'success')
                  this.$router.push('/Approve/ApprovalList?ViewId=SupplierLifecycle')
                })
              }
            })
          }).catch(() => { FlowCtlData.formAlert(this, 'info') })
        }
      }).catch(() => { FlowCtlData.formAlert(this, 'error') })
    },
    pass () { // 通过
      FlowCtlData.getNodeAction('TJ', this.form).then((val) => {
        if (val) {
          FlowCtlData.alertDialog(this).then(() => {
            FlowCtlData.setFormData(FlowCtlData, this.form)
            request('/api/supplier/supplierLevel/updateApprovalStatus', 'get', this.form).then((Response) => {
              if (Response.code === '200') {
                FlowCtlData.setNodeAction().then((val) => {
                  FlowCtlData.formAlert(this, 'success')
                  this.$router.push('/Approve/ApprovalList?ViewId=SupplierLifecycle')
                })
              }
            })
          }).catch(() => { FlowCtlData.formAlert(this, 'info') })
        }
      }).catch(() => { FlowCtlData.formAlert(this, 'error') })
    },
    handleShow () {
      this.adjustmentdialogShow = true
    },
    handlePreview (fileList) {
      fileHandle.handlePreview(fileList)
    },
    handleSuccess (response, file, fileList) {
      if (response.code === '200') {
        response.data.forEach(element => {
          this.form.fileList.push(element)
        })
      }
    },
    back: () => {
      window.history.back()
    }
  }
}
</script>

<style scoped lang='scss'>
@import '@/assets/css/elForm.scss';
@import '@/assets/css/tableList.scss';
  .action{
    margin-top: 35px;
  }
  .mainform{
    .form{
      :deep(.el-form .col){
        margin: 3px 0;
      }
    }
  }
</style>
